import React, { useState, useEffect } from 'react'

import {Tag, Button, Space, Table, Col, Tooltip, Row, Typography, Pagination, Breadcrumb, Input} from 'antd';
import {SettingOutlined, UnlockOutlined, LockOutlined, DeleteOutlined} from "@ant-design/icons";
import {withTranslation} from "react-i18next";
import instance from "../../store/axios";
import sender from '../../modules/template/controllers/sender';
import hashController from '../../modules/template/controllers/hash.controller';

const { Title } = Typography;

function UsersList({ t }) {
    const [update, setUpdate] = useState(0);
    const [offset, setOffset] = useState(1);
    const [users, setUsers] = useState({
        list: [],
        count: 0,
        pagination: 1
    });

    const getUsers = () => {
        instance.get('/users/users/?page=' + offset)
            .then(response => {
            console.log(response);
            setUsers({
                ...users,
                list: response.data.data,
                pagination: response.data.pagination
            });
        })
    }

    const editUser = (user_id, data) => {

        const facts = new FormData()
        facts.append('ban', data.ban)
        facts.append("hash", hashController.setHash(facts))

        sender.post(`/user/useredit/${user_id}`, facts)
            .then(result => {
                console.log(result)
                getUsers()
            })
            .catch(error => {
                console.log(error)
            })
    }

    const deactivateUser = (user_id) => {
        const data = {
            ban: 1
        }
        editUser(user_id, data)
    }

    const activateUser = (user_id) => {
        const data = {
            ban: 0
        }
        editUser(user_id, data)
    }

    const deleteUser = (user_id) => {
        if(window.confirm("Вы действительно хотите удалить пользователя и все его диалоги?"))
            sender.delete(`/user/user/${user_id}`)
                .then(result => {
                    console.log(result)
                    getUsers()
                })
                .catch(error => {
                    console.log(error)
                })
    }

    useEffect(() => {
        getUsers()
    }, [update]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: "Никнейм",
            dataIndex: 'nikname',
            key: 'nikname'
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'ФИО',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (el, record) => `${el} ${record.lastname}`
        },
        {
            title: t('users:actions'),
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Space>
                    {record.ban > 0
                        ? <Tooltip title="Разблокировать">
                                <Button type="dashed" shape="circle" onClick={() => activateUser(record.id)} icon={<UnlockOutlined />} />
                            </Tooltip>
                        :  <Tooltip title="Заблокировать">
                                <Button type="dashed" danger shape="circle" onClick={() => deactivateUser(record.id)} icon={<LockOutlined />} />
                            </Tooltip>
                    }
                    <Tooltip title={t('users:view')}>
                        <Button type="dashed" shape="circle" href={"/users/" + record.id} icon={<SettingOutlined />} />
                    </Tooltip>
                    <Tooltip title="Удалить пользователя">
                        <Button type="dashed" shape="circle" onClick={() => deleteUser(record.id)} icon={<DeleteOutlined />} danger />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handlePagination = (value) => {
        setOffset(value);
        setUpdate(update + 1);
    };

    const search = (value) => {
        console.log(value.target.value)

        instance.get('/users/users/?search=' + value.target.value)
            .then(response => {
                console.log(response);
                setUsers({
                    ...users,
                    list: response.data.data,
                    pagination: {
                        count: 0,
                        pagination: 1
                    }
                });
            })
    }


    return(
        <>
            <Row justify="space-around" align="middle" className="template-title">
                <Col span={16}>
                    <Title>{t('users:listUsers')}</Title>
                </Col>
                <Col span={8}>

                </Col>
                <Col span={24} className="template-breadcrumb">
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{t('users:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t('users:users')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col span={24}>
                    <Input
                        placeholder="Поиск но никнейму или или ФИО"
                        style={{ maxWidth: `300px` }}
                        onChange={search}
                    />
                </Col>
            </Row>
            <br/>
            <Table columns={columns} dataSource={users.list} pagination={false} defaultPageSize={users.pagination[2]} />
            <br/>
            <Pagination
                defaultCurrent={users.pagination[0]}
                total={users.pagination[1]}
                showSizeChanger={false}
                onChange={handlePagination}
            />
        </>
    )
}

export default withTranslation()(UsersList);